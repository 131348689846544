<template>
  <div class="wrap">
    <!-- 表单区域 -->
    <div class="facilityBox">
      <el-breadcrumb separator="/" style="margin: 0 0 10px 0">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>同意分成</el-breadcrumb-item>
        <el-breadcrumb-item>服务商信息</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="position: absolute; right: 20px; top: 125px; z-index: 999">
        <el-tooltip placement="top">
          <div slot="content">
            1.各证照根据对应资质上传，并保证真实有效<br />2.重新提交，将重新审核，直到再次通过前，无法交易<br />3.参与多种业务，在“其他证件”里上传多个证照<br />4.财务经办人使用企业一般户充值、提现。财务经办人手机务必真实有效，用于获取验证码<br />5.根据监管要求，必须提交企业基本户、受益人、联系地址、4级行业分类等信息<br />6.企业用户需上传实物印章的印模，确保与企业名称、实物印章一致、完整。以上所有信息错漏导致的损失，由企业自行承担
          </div>
          <div style="margin: 0px 20px; color: #0f5fff">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>
      <el-form label-width="180px" :inline="true" disabled>
        <div class="title">发票类型 / 业务类型</div>
        <template v-for="(item, index) in selectlist">
          <el-form-item
            v-if="item.Code != '05'"
            :key="index"
            :label="`${item.Name}：`"
          >
            <div style="width: 326px">
              <div v-for="(lebel, inx) in item.lebel" :key="inx">
                {{ lebel }}
              </div>
              <div>需提供证件照：{{ item.LicenseName.toString() }}</div>
            </div>
          </el-form-item>
        </template>
        <div class="title">认证信息</div>
        <div class="content">
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.LegalPersonIDCardOnURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">法人身份证(正面)</div>
          </div>
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.LegalPersonIDCardBackURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">法人身份证(反面)</div>
          </div>
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.CFOIDCardUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">财务经办人身份证(正面)</div>
          </div>
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.CFOIDCardBackUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">财务经办人身份证(反面)</div>
          </div>
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.BusinessLicenseURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">营业执照(原件三合一正本)</div>
          </div>
        </div>
        <div class="content">
          <div class="upBox" v-if="confirmInfo.RTBusinessLicenseURL">
            <el-image
              class="img"
              :src="confirmInfo.RTBusinessLicenseURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">道路运输经营许可证</div>
          </div>
          <div class="upBox" v-if="confirmInfo.BankCardUrl">
            <el-image
              class="img"
              :src="confirmInfo.BankCardUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">企业银行卡</div>
          </div>
          <div class="upBox" v-if="confirmInfo.OfficialSealUrl">
            <el-image
              class="img"
              :src="confirmInfo.OfficialSealUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">企业印章</div>
          </div>
          <div class="upBox" v-for="(item, index) in eInfoEx" :key="index">
            <el-image
              class="img"
              :src="item.ImageURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">其他证件</div>
          </div>
        </div>
      </el-form>
      <el-form label-width="180px" :inline="true" disabled>
        <!-- 基本信息 -->
        <div class="title">基本信息</div>
        <el-form-item label="法人姓名" prop="LegalPersonIName">
          <el-input
            v-model="basicInfoForm.LegalPersonIName"
            placeholder="请输入法人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="LegalPersonIIDCard">
          <el-input
            v-model="basicInfoForm.LegalPersonIIDCard"
            placeholder="请输入法人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人手机号码" prop="LegalPersonlPhone">
          <el-input
            v-model="basicInfoForm.LegalPersonlPhone"
            placeholder="请输入法人手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="法人身份证起始日期"
          prop="LegalPersonalIDCardStartDate"
        >
          <el-date-picker
            v-model="basicInfoForm.LegalPersonalIDCardStartDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择财务经办人身份证有效期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="法人身份证有效期" prop="LegalPersonlIDCardDate">
          <el-date-picker
            v-model="basicInfoForm.LegalPersonlIDCardDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择法人身份证有效期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="法人银行卡号" prop="LegalPersonBankCardNo">
          <el-input
            v-model="ebaseInfoEx.LegalPersonBankCardNo"
            placeholder="请输入法人银行卡号"
          ></el-input>
        </el-form-item>
        <el-form-item label="财务经办人姓名" prop="CFOName">
          <el-input
            v-model="ebaseInfoEx.CFOName"
            placeholder="请输入经办人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="财务经办人身份证号" prop="CFOIDCardNo">
          <el-input
            v-model="ebaseInfoEx.CFOIDCardNo"
            placeholder="请输入财务经办人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="财务经办人手机号码" prop="CFOPhone">
          <el-input
            v-model="ebaseInfoEx.CFOPhone"
            placeholder="请输入财务经办人手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="财务经办人身份证起始日期"
          prop="CFOIDCardStartDate"
        >
          <el-date-picker
            v-model="ebaseInfoEx.CFOIDCardStartDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择财务经办人身份证有效期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="财务经办人身份证有效期" prop="CFOIDCardValidDate">
          <el-date-picker
            v-model="ebaseInfoEx.CFOIDCardValidDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择财务经办人身份证有效期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="企业性质" prop="BenefitWay">
          <el-input
            v-model="ebaseInfoEx.BenefitWayName"
            placeholder="请输入企业性质"
          ></el-input>
        </el-form-item>
        <div class="content content1" style="justify-content: left">
          <div class="up" @click="flag.isBeneficiary = true">查看受益人</div>
        </div>
        <!-- 企业信息 -->
        <div class="title">企业信息</div>
        <el-form-item label="企业名称" prop="EnterpriseFullName">
          <el-input
            v-model="basicInfoForm.EnterpriseFullName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="EnterpriseCreditCode">
          <el-input
            v-model="basicInfoForm.EnterpriseCreditCode"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照住所" prop="EnterpriseAddress">
          <el-input
            v-model="basicInfoForm.EnterpriseAddress"
            placeholder="请输入营业执照住所"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照注册日期" prop="EnterpriseRegisterDate">
          <el-date-picker
            v-model="basicInfoForm.EnterpriseRegisterDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照注册日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="注册国家" prop="RegisterNation">
          <el-input
            v-model="ebaseInfoEx.RegisterNationName"
            placeholder="请输入注册国家"
          ></el-input>
        </el-form-item>
        <el-form-item label="境内/境外" prop="DomesticOverseas">
          <el-input
            v-model="ebaseInfoEx.DomesticOverseasName"
            placeholder="请输入境内/境外"
          ></el-input>
        </el-form-item>
        <el-form-item label="法定代表人" prop="LegalPersonIName">
          <el-input
            v-model="basicInfoForm.LegalPersonIName"
            placeholder="请输入法定代表人"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照有效日期" prop="EnterpriseEffectiveDate">
          <el-date-picker
            v-model="basicInfoForm.EnterpriseEffectiveDate"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="注册资本" prop="RegisteredCapital">
          <el-input
            v-model="basicInfoForm.RegisteredCapital"
            placeholder="请输入注册资本"
          ></el-input>
        </el-form-item>
        <el-form-item label="税务登记号" prop="TAXID">
          <el-input
            v-model="basicInfoForm.TAXID"
            placeholder="请输入税务登记号"
          ></el-input>
        </el-form-item>
        <el-form-item label="经营范围" prop="BusinessScope">
          <el-input
            v-model="basicInfoForm.BusinessScope"
            placeholder="请输入经营范围"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业所在省市区" prop="provinces">
          <el-input
            v-model="basicInfoForm.provinces"
            placeholder="请输入企业所在省市区"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业所在道路门牌" prop="Road">
          <el-input v-model="basicInfoForm.Road"></el-input>
        </el-form-item>
        <el-form-item label="企业行业分类" prop="tcDdat">
          <el-input
            v-model="basicInfoForm.tcDdat"
            placeholder="请输入企业行业分类"
          ></el-input>
        </el-form-item>
        <div class="title">开票信息</div>
        <div style="width: 100%; display: flex">
          <div style="width: 60%; display: flex">
            <div>
              <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
                <el-input
                  v-model="titleInfo.InvoiceTitleName"
                  placeholder="请输入发票企业抬头"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
                <el-input
                  v-model="titleInfo.InvoiceTitleCreditCode"
                  placeholder="请输入纳税人识别号"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业联系电话" prop="invoiceTitlePhone">
                <el-input
                  v-model="titleInfo.InvoiceTitlePhone"
                  placeholder="请输入企业联系电话"
                ></el-input>
              </el-form-item>
              <el-form-item label="开户行所在地" prop="bankAddress">
                <el-input v-model="vehicleForm.bankAddress"></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
                <el-input
                  v-model="titleInfo.InvoiceTitleAddress"
                  placeholder="请输入企业联系地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="银行账户" prop="invoiceTitleBankerNumber">
                <el-input
                  v-model="titleInfo.InvoiceTitleBankerNumber"
                  placeholder="请输入银行基本账号"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="银行账户开户行"
                prop="InvoiceTitleBankerName"
              >
                <el-input
                  v-model="titleInfo.InvoiceTitleBankerName"
                  placeholder="请输入银行账户开户行"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人配置" prop="NsrType">
                <el-select v-model="titleInfo.NsrType" placeholder="请选择">
                  <el-option
                    v-for="item in affiliation"
                    :key="item.Name"
                    :label="item.Name"
                    :value="item.Code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div
            class="upBox"
            style="margin-left: 10%; width: 278px; text-align: center"
          >
            <el-image
              class="img"
              :src="confirmInfo.AccountLicenceUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">开户证明</div>
          </div>
        </div>
        <!-- 服务商开票面额 -->
        <div class="title">服务商开票面额</div>
        <div class="fromBox">
          <el-form-item label="服务商可开发票面额" label-width="160px">
            <el-input
              maxlength="8"
              v-model="denomination.Denomination"
              placeholder="请输入面额数值，如万元面额，则输入10000"
            >
            </el-input>
          </el-form-item>
        </div>
        <!-- 基本存款账户 -->
        <div class="title">基本存款账户</div>
        <el-form-item label="基本存款账户账户名称" prop="BDAName">
          <el-input
            v-model="ebaseInfoEx.BDAName"
            placeholder="请输入基本存款账户账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户账户号码" prop="BDANo">
          <el-input
            v-model="ebaseInfoEx.BDANo"
            placeholder="基本存款账户账户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户开户行" prop="BDABankName">
          <el-input
            v-model="ebaseInfoEx.BDABankName"
            placeholder="请输入基本存款账户开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户编号" prop="BDANumber">
          <el-input
            v-model="ebaseInfoEx.BDANumber"
            placeholder="基本存款账户编号"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btnWrap">
        <el-button size="medium" @click="routerBack()" icon="el-icon-arrow-left"
          >返回</el-button
        >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-search"
          @click="GetGetAppendixList()"
          >查看附件</el-button
        >
      </div>
    </div>
    <!-- 查看附件 -->
    <el-dialog class="addDialog" :visible.sync="isExchange" width="1000px">
      <span slot="title" class="dialog-title"> 查看附件 </span>
      <upLoadAccessory
        :pactlist="pactlist"
        :pactData="pactData"
        :pactLoading="pactLoading"
        :isAccessory="flag.isAccessory"
      >
      </upLoadAccessory>
    </el-dialog>
    <!-- 受益人弹窗 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isBeneficiary"
      width="1200px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 查看受益人 </span>
      <BeneficiaryList
        :beneficiaryData="beneficiaryData"
        :isOpenBeneficiary="flag.isOpenBeneficiary"
      >
      </BeneficiaryList>
    </el-dialog>
  </div>
</template>

<script>
import upLoadAccessory from "@/components/businessCmpt/upLoadAccessory";
import { GetenterPriseInfo } from "@/api/auditInfo/auditOwner/index";
import { getDataDict } from "@/api/common/common";
import { GetGetAppendixList } from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
import BeneficiaryList from "@/components/businessCmpt/beneficiarylist";
export default {
  data() {
    return {
      // 受益人数据
      beneficiaryData: [
        {
          id: 1,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 2,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 3,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 4,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 5,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 6,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 7,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 8,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 9,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 10,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
      ],
      // 状态管理
      flag: {
        // 受益人弹窗
        isBeneficiary: false,
        // 是否开启操作受益人身份证信息
        isOpenBeneficiary: false,
        // 是否可操作附件
        isAccessory: false,
      },
      // 查看附件
      isExchange: false,
      // 附件数据
      pactlist: [],
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 附件表格load
      pactLoading: false,
      // 发票类型数据
      selectlist: [],
      // 选中的费率类型
      radio: "01",
      // 基本信息
      basicInfoForm: "",
      // 证件信息
      confirmInfo: "",
      // 抬头信息
      vehicleForm: "",
      // 发票面额
      denomination: "",
      UserID: "",
      // 图片放大预览
      srcList: [],
      // 其他证件
      eInfoEx: "",
      ebaseInfoEx: "",
      titleInfo: "",
      // Tab状态
      urlType: "",
      pageIndex: 1,
      affiliation: [],
    };
  },
  activated() {
    this.pageIndex = this.$route.query.pageIndex
      ? this.$route.query.pageIndex
      : 1;
    this.UserID = this.$route.query.UserID;
    this.urlType = this.$route.query.urlType;
    // 查询货主/服务商信息
    this.GetenterPriseInfo(this.$route.query.UserID);
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      res.patterSetInfo.forEach((item) => {
        item.lebel = [];
        item.LicenseName = [];
      });
      this.selectlist = res.patterSetInfo;
    });
    // 获取纳税人配置
    getDataDict({ type: 68 }).then((res) => {
      this.affiliation = res.patterSetInfo;
    });
  },
  created() {
    // this.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1
    // this.UserID = this.$route.query.UserID;
    // this.urlType = this.$route.query.urlType
    // // 查询货主/服务商信息
    // this.GetenterPriseInfo(this.$route.query.UserID);
    // // 获取发票类型
    // getDataDict({ type: 20 }).then((res) => {
    //   res.patterSetInfo.forEach(item => {
    //     item.lebel = []
    //     item.LicenseName = []
    //   })
    //   this.selectlist = res.patterSetInfo;
    // })
  },
  components: {
    BeneficiaryList,
    upLoadAccessory,
  },
  methods: {
    // 返回上一页
    routerBack() {
      this.$router.back();
      // this.$router.push({
      //   path: "/auditInfo/agreeService/index",
      //   query: {
      //     urlType: this.urlType,
      //     pageIndex: this.pageIndex
      //   },
      // });
    },
    // 下载附件
    upPact(item) {
      window.location.href = item.FileUrl;
    },
    // 附件分页
    pactChange(e) {
      this.pactLoading = true;
      this.pactData.page = e;
      this.GetGetAppendixList();
    },
    GetGetAppendixList() {
      let data = {
        PageIndex: this.pactData.page,
        PageSize: this.pactData.pagesize,
        UserID: this.UserID,
      };
      GetGetAppendixList({ Json: JSON.stringify(data) }).then((res) => {
        this.isExchange = true;
        this.pactData.total = res.data.TotalCount;
        this.pactlist = res.data.DataList;
        this.pactLoading = false;
      });
    },
    // 查询货主/服务商信息
    GetenterPriseInfo(UserID) {
      GetenterPriseInfo({ UserID }).then((res) => {
        // 处理受益人列表
        res.eBeneficiary.forEach((item, index) => {
          item.id = Number(index) + 1;
          this.beneficiaryData[index] = item;
        });
        if (res.baseInfo) {
          this.basicInfoForm = res.baseInfo;
          this.radio = res.baseInfo.InvoiceType;
          this.titleInfo = res.titleInfo;
          if (res.baseInfo.Province) {
            this.basicInfoForm.provinces = `${res.baseInfo.Province} / ${res.baseInfo.City} / ${res.baseInfo.County}`;
          }
          if (res.baseInfo.TCKind) {
            this.basicInfoForm.tcDdat = `${res.baseInfo.TCKind} / ${res.baseInfo.TCBig} / ${res.baseInfo.TCMid} / ${res.baseInfo.TCSmall}`;
          }
        }
        if (res.confirmInfo) {
          this.confirmInfo = res.confirmInfo;
          // 用于大图展示
          if (res.confirmInfo.LegalPersonIDCardOnURL) {
            this.srcList.push(res.confirmInfo.LegalPersonIDCardOnURL);
          }
          if (res.confirmInfo.LegalPersonIDCardBackURL) {
            this.srcList.push(res.confirmInfo.LegalPersonIDCardBackURL);
          }
          if (res.confirmInfo.BusinessLicenseURL) {
            this.srcList.push(res.confirmInfo.BusinessLicenseURL);
          }
          if (res.confirmInfo.RTBusinessLicenseURL) {
            this.srcList.push(res.confirmInfo.RTBusinessLicenseURL);
          }
          if (res.confirmInfo.NetFTSpecialInvoiceLicenseURL) {
            this.srcList.push(res.confirmInfo.NetFTSpecialInvoiceLicenseURL);
          }
          if (res.confirmInfo.NetRTBusinessLicenseURL) {
            this.srcList.push(res.confirmInfo.NetRTBusinessLicenseURL);
          }
          if (res.confirmInfo.BankCardUrl) {
            this.srcList.push(res.confirmInfo.BankCardUrl);
          }
          if (res.confirmInfo.OthersUrl) {
            this.srcList.push(res.confirmInfo.OthersUrl);
          }
          if (res.confirmInfo.CFOIDCardUrl) {
            this.srcList.push(res.confirmInfo.CFOIDCardUrl);
          }
          if (res.confirmInfo.CFOIDCardBackUrl) {
            this.srcList.push(res.confirmInfo.CFOIDCardBackUrl);
          }
          if (res.confirmInfo.BeneficiaryIDCardUrl) {
            this.srcList.push(res.confirmInfo.BeneficiaryIDCardUrl);
          }
          if (res.confirmInfo.BeneficiaryIDCardBackUrl) {
            this.srcList.push(res.confirmInfo.BeneficiaryIDCardBackUrl);
          }
          if (res.confirmInfo.AccountLicenceUrl) {
            this.srcList.push(res.confirmInfo.AccountLicenceUrl);
          }
          if (res.confirmInfo.OfficialSealUrl) {
            this.srcList.push(res.confirmInfo.OfficialSealUrl);
          }
        }
        if (res.ebaseInfoEx) {
          this.ebaseInfoEx = res.ebaseInfoEx;
        }
        if (res.titleInfo) {
          this.vehicleForm = res.titleInfo;
          //拼接开户行所在地
          this.vehicleForm.bankAddress = `${this.vehicleForm.BankProvince}/${this.vehicleForm.BankCity}/${this.vehicleForm.BankArea}`;
        }
        if (res.denomination) {
          this.denomination = res.denomination[0];
        }
        if (res.eInfoEx[0].ImageURL) {
          res.eInfoEx.forEach((item) => {
            this.srcList.push(item.ImageURL);
          });
          this.eInfoEx = res.eInfoEx;
        }
        if (res.eInvoiceTypeList) {
          res.eInvoiceTypeList.forEach((item) => {
            this.selectlist.forEach((select) => {
              if (item.InvoiceTypeName == select.Name) {
                let TaskName = `${item.TaskTypeName} / ${item.TaskSceneName}`;
                select.lebel.push(TaskName);
                select.LicenseName.push(item.LicenseName);
              }
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.wrap {
  .el-input.is-disabled .el-input__inner {
    color: #333 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #409eff !important;
  }

  .facilityBox .el-input__inner {
    max-width: 200px;
  }

  .facilityBox .el-date-editor.el-input {
    width: 200px;
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.el-form {
  //display: flex;
  //flex-wrap: wrap;
  .el-form-item {
    min-width: 250px;
  }

  .el-date-editor,
  .el-select {
    width: 100%;
  }
}

.up {
  text-align: center;
  width: 100px;
  height: 40px;
  cursor: pointer;
  color: #fff;
  background: #409eff;
  font-size: 14px;
  border-radius: 5px;
  line-height: 40px;
}

.fromBox {
  box-sizing: border-box;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: flex-start;
}

.selectWrap {
  width: 100%;
  padding: 30px 28%;
  box-sizing: border-box;
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.img {
  width: 288px;
  height: 178px;
  border-radius: 10px;
}

.topWrap {
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 20px;
    padding: 4px 0 0 0;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .upBox {
    text-align: center;

    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0;
      color: #333333;
    }
  }
}

.title {
  color: $primary;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 20px 0;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}
</style>
